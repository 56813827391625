import supabaseClient from "../../lib/supabase-client/supabaseClient";
import {SupabaseClient} from "@supabase/supabase-js";
import {AppUser, BeltRanks} from "../../domain/models/models";

interface AppUserDTO {
  userID: number;
  authUserID: string;
  displayName?: string | null;
  gymID?: number | null;
  beltRankEnumStr?: string | null;
  profileImageFileName?: string | null;
}

interface GymDTO {
  id: number;
  name: string;
  address: string;
  mobile?: string | null;
}

// From Supabase DB row to DTO
function recordToAppUserDTO(map: Record<string, any>): AppUserDTO {
  if (!map.user_id || !map.auth_id) {
    throw new Error('Map must contain "user_id" and "auth_id" keys');
  }

  return {
    userID: map.id,
    authUserID: map.auth_id,
    displayName: map.display_name || null,
    gymID: map.gym_id || null,
    beltRankEnumStr: map.belt_rank || null,
    profileImageFileName: map.profile_image_file_name || null,
  };
}

// From Supabase DB row to DTO
function recordToGymDTO(map: Record<string, any>): GymDTO {
  if (!map.gym_id || !map.display_name || !map.address) {
    throw new Error('Map must contain "id", "name", and "address" keys');
  }

  return {
    id: map.id,
    name: map.name,
    address: map.address,
    mobile: map.mobile || null,
  };
}

export class AppUserRepository {
  private static instance: AppUserRepository;
  private supabase : SupabaseClient<any, "public", any>;

  private constructor() {
    // Initialize the Supabase client with your project details
    this.supabase = supabaseClient;
  }

  public static getInstance(): AppUserRepository {
    if (!AppUserRepository.instance) {
      AppUserRepository.instance = new AppUserRepository();
    }
    return AppUserRepository.instance;
  }

  /**
   * Fetches an AppUserDTO based on the given authUserID.
   * @param authUserID - The auth_user_id to search for.
   * @returns A promise that resolves to an AppUserDTO or null if not found.
   */
  async fetchByAuthID(authUserID: string): Promise<AppUser | null> {
    const { data: userData, error: userFetchError } = await this.supabase
      .from('user')
      .select('*')
      .eq('auth_id', authUserID)
      .single(); // Fetch a single user

    if (userFetchError) {
      console.error('Error fetching user:', userFetchError.message);
      throw userFetchError;
    }

    if (!userData) {
      return null;
    }

    const userDTO = recordToAppUserDTO(userData);

    const { data : gymData, error: gymFetchError } = await this.supabase
      .from('gym')
      .select('*')
      .eq('gym_id', userDTO.gymID)
      .single(); // Fetch a single user

    if (gymFetchError) {
      console.error('Error fetching gym:', gymFetchError.message);
      throw gymFetchError;
    }

    if (!gymData) {
      throw new Error('Gym not found: ' + userDTO.gymID);
    }

    const gymDTO = recordToGymDTO(gymData);

    var profileImageURL = 'https://via.placeholder.com/150'
    if (userDTO.profileImageFileName) {
      // Get the public URL for the profile image
      profileImageURL = this.supabase.storage
        .from('profile-images').
        getPublicUrl(userDTO.profileImageFileName).data.publicUrl
    }

    // Convert the plain data to AppUserDTO
    return {
      userID: userDTO.userID,
      authUserID: userDTO.authUserID,
      displayName: userDTO.displayName,
      gym: {
        gymID: gymDTO.id,
        name: gymDTO.name,
        address: gymDTO.address,
        mobile: gymDTO.mobile || null,
      },
      beltRank: userDTO.beltRankEnumStr ? BeltRanks.fromString(userDTO.beltRankEnumStr) : null,
      profileImageURL: profileImageURL,
    };
  }
}
